import React, { useEffect, useState } from "react";
import { useAuthUserContext } from "../../context/UserContext";
import ContentSection from "../App/ContentSection";
import PageHeader from "../App/PageHeader";
import Avatar from "./Avatar";
import { Redirect } from "react-router-dom";

import styles from "./MyProfilePage.module.css";
import Button from "../UserInterfaceElements/Button";
import DarkPage from "../UserInterfaceElements/DarkPage";

const MyProfilePage: React.FC = () => {
  const { user, /*updateMyProfile, error,*/ logout, loggedIn  } = useAuthUserContext();
  // const [name, setName] = useState<string | undefined>("");
  // const [email, setEmail] = useState<string | undefined>("");
  const [avatarJsx, setAvatarJsx] = useState<boolean>(false);

  const doLogout = async () => {
    try {
      logout();
    } catch (ex) {
      console.error(ex);
    }
  };


  // useEffect(() => {
  //   setName(user?.name || "");
  //   setEmail(user?.email || "");
  // }, [user]);

  useEffect(() => {
    setAvatarJsx( true );
  }, [user?.avatar]);


  if (!loggedIn) {
    return <Redirect to="/" />;
  }
  
  if (!user) {
    return (
      <div className={styles.MyProfilePage}>
        <PageHeader>No user</PageHeader>
      </div>
    );
  }

  return (<DarkPage>
    <div className={styles.MyProfilePage}>
      <PageHeader>Edit Account</PageHeader>

      
      <ContentSection>
        {/*<p className={styles.profile_edit_field}>
          <label>Name</label>
          <input
            type="text"
            value={name}
            onChange={(evt) => setName(evt.target.value)}
          />
        </p>
        <p className={styles.profile_edit_field}> 
          <label>Email</label>
          <input
            type="text"
            value={email}
            onChange={(evt) => setEmail(evt.target.value)}
          />
        </p>
        <button onClick={(evt) => updateMyProfile({ name, email })}>
          update
  </button>*/}
        {avatarJsx ? <Avatar user={user} showLink={"/avatarpicker"} /> : false}
        {/* <div className={styles.ButtonBar}> */}
          <Button to="/avatarpicker">
            Change your avatar
          </Button>
          {/* <div /> */}
          <Button onClick={doLogout} >
            log out
          </Button>
        {/* </div> */}
      </ContentSection>
    </div></DarkPage>
  );
};

export default MyProfilePage;
