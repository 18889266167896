import React, { useEffect, useState } from "react";

import styles from "./ModeratePopupsPage.module.css";
// import classnames from "classnames";
import { Link, useHistory /*, useParams*/ } from "react-router-dom";
import useModeratePopup from "../../hooks/useModeratePopup";
import usePopup from "../../hooks/usePopup";
//import { useManifestoContext } from "../../context/ManifestoContext";
import PageHeader from "../App/PageHeader";
import ContentSection from "../App/ContentSection";
import Breadcrumb from "../UserInterfaceElements/Breadcrumb";

// interface IRouteParams {
//   id: string;
// }

const ModeratePopupPage: React.FC = () => {
  //const { id } = useParams<IRouteParams>();
  const id = "1";
  const popup = usePopup();
  
  const [message, setMessage] = useState<string>("");
  const [active, setActive] = useState<boolean>(false);
  //const [source, setSource] = useState<string>("");
  // const [addToMyManifesto, _setAddToMyManifesto] = useState(true);
  const { moderate } = useModeratePopup();
  const history = useHistory();
  const [breadcrumb, setBreadcrumb] = useState<Array<JSX.Element>>([<></>]);

  const maxPopupLength = 280;
  //console.log(popup);


  useEffect(() => {
    if (popup) {
      console.log(popup);
      setMessage(popup.message);
      setActive( popup.active ? true : false);
    }
    window.location.pathname.substring(0, 9) === "/moderate" && setBreadcrumb([
      <Link to="/moderate/" >Moderation</Link>
    ]);
  }, [popup, history]);

  return (
    <div className={styles.AddPopupPage}>
      <Breadcrumb linkStack={breadcrumb} />
      <PageHeader>Moderate Popup</PageHeader>
      <ContentSection>
        <p>Write a new popup, others can also add it to their manifesto.</p>
        <textarea value={message} onChange={(evt) => setMessage(evt.target.value)} maxLength={maxPopupLength} />
        <label>
          Active:
          <input
            type="checkbox"
            checked={active}
            value="1"
            onChange={(evt) => setActive(evt.target.checked)}
            style={{width:"auto"}}
          />
        </label>

        {popup && <button disabled={!message} onClick={() => {
          moderate( id, active, message.replace(new RegExp("(^(\"|')*|(\"|')*$)*", "g"), ""),); history.goBack(); 
        }} >
          Update popup
        </button>}
      </ContentSection>
    </div>
  );
};

export default ModeratePopupPage;
