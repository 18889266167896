import React from "react";
import styles from "./Button.module.css";
import { Link } from "react-router-dom";

interface IProps {
  onClick?: Function | undefined;
  disabled?: boolean | undefined;
  children: JSX.Element | string;
  className?: string | undefined;
  type?: "button" | "submit" | "reset" | undefined;
  to?:string;
  altStyle?:boolean;
}

const Button: React.FC<IProps> = ({ onClick, disabled,children,to, className, altStyle=false, type=undefined  }) => {
  const renderClassName = altStyle ? styles.ButtonAlt : styles.Button;
  return (
    to ? <Link
      to={to}
      className={className ? `${className} ${renderClassName}` : renderClassName}
    >{children}</Link> :
    <button
      disabled={disabled}
      onClick={(e) => onClick && onClick() }
      className={className ? `${className} ${renderClassName}` : renderClassName}
      type={type && type}
    >{children}</button>
  );
};

export default Button;
