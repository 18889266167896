import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

import { useManifestoContext } from "../../context/ManifestoContext";
import { IStatement } from "../../context/StatementsContext";
import { useAuthUserContext } from "../../context/UserContext";
import useManifesto from "../../hooks/useManifesto";

import styles from "./ManifestoPage.module.css";

import { ReactComponent as BarDragIcon } from "../../assets/icon_bar_drag.svg";
import { ReactComponent as StatementDragIcon } from "../../assets/icon_statement_drag.svg";
import { ReactComponent as AddToManifestoIcon } from "../../assets/icon_addToManifesto.svg";
import StatementRow from "./StatementRow";
import PageHeader from "../App/PageHeader";
import DarkPage from "../UserInterfaceElements/DarkPage";

interface IRouteParams {
  id: string;
}

const ManifestoPage: React.FC = () => {
  const { id } = useParams<IRouteParams>();
  const { setPublicCount, reorder, add, contains, reload } = useManifestoContext();
  const { user: loggedInUser } = useAuthUserContext();
  const { manifesto } = useManifesto(id);
  
  const [ isMyManifesto, setIsMyManifesto ] = useState(false);
  useEffect( () => {
    if(document.location.pathname === '/manifesto/me'){
      if(manifesto?.creator?.uuid !== loggedInUser?.uuid){
        //setIsMyManifesto( true);
        reload();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser])

  useEffect( () => {
    // console.log("test manifesto ownershipo", id, manifesto?.creator?.uuid,  loggedInUser?.uuid);
    
    setIsMyManifesto(  loggedInUser ? manifesto?.creator?.uuid === loggedInUser?.uuid : false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manifesto])

  // const isMyManifesto =
  //   loggedInUser && manifesto?.creator?.uuid === loggedInUser?.uuid;

  if (!manifesto) {
    return <div>loading....</div>;
  }

  let publicManifesto = manifesto.statements || [];
  let privateManifesto: IStatement[] = [];
  if (isMyManifesto) {
    publicManifesto = manifesto.statements.slice(0, manifesto.publicCount);
    privateManifesto = manifesto.statements.slice(manifesto.publicCount);
  }

  const onDragEnd = (result: DropResult) => {
    // console.log("onDragEnd.DropResult", result.source, result.destination);
    if (!result.destination) {
      return;
    }
    let sourceIdx = result.source.index;
    if (result.source.droppableId === "private") {
      sourceIdx += publicManifesto.length;
    }
    let destinationIdx = result.destination.index;
    if (result.destination.droppableId === "private") {
      destinationIdx += publicManifesto.length;
    } else if (result.source.droppableId=== "public" && result.destination.droppableId === "drop-sep"){
      // result.destination.index = result.destination.index -1;
      // console.log("from p[ublic to private", destinationIdx);
      result.destination.index = (publicManifesto.length - 1);
      destinationIdx = publicManifesto.length ;
      //destinationIdx = result.destination.index-1;
    }else if (result.destination.droppableId === "drop-sep") {
      destinationIdx = publicManifesto.length;
    } 

    // console.log("onDragEnd.ModifiedDropResult", result);
    if (result.draggableId === "drag-sep") {
      // console.log("dragging the seperator");
      if(result.destination.droppableId !== result.source.droppableId && result.destination.droppableId === "private") {
        // console.log("dragging the seperator downward, to have more public stuff and less bookmark stuff");
        if(result.destination.index === privateManifesto.length){
          // console.log(`dragged the seperator to the end of private stuff, making everything public (set the publicCount to ${manifesto.statements.length}`);
          setPublicCount( manifesto.statements.length);
        }else{
          // console.log("dragged the seperator into the private stuff, making more things public");
          setPublicCount(result.destination.index + publicManifesto.length);
        }
      }else{
        // console.log("dragging the seperator upward, to have less public stuff and more bookmark stuff");
        setPublicCount(result.destination.index);
      }
    } else if (result.source.droppableId === "drop-sep" && result.destination.index !== 0) {
      setPublicCount(destinationIdx);
    } else {
      reorder(sourceIdx, destinationIdx);
      if (result.destination.droppableId === "drop-sep") {
        if (
          result.destination.index === 0 &&
          result.source.droppableId === "private"
        ) {
          setPublicCount(manifesto.publicCount + 1);
        } else if (
          result.destination.index === 1 &&
          result.source.droppableId === "public"
        ) {
          setPublicCount(manifesto.publicCount - 1);
        } else if (
          result.destination.index >= publicManifesto.length-1 &&
          result.source.droppableId === "public"
        ) {
          setPublicCount(manifesto.publicCount - 1);
        } else if (
          result.destination.index === 0 &&
          result.source.droppableId === "public"
        ) {
          setPublicCount(0);
        }
      } else if (result.destination.droppableId !== result.source.droppableId) {
        if (result.destination.droppableId === "public") {
          setPublicCount(manifesto.publicCount + 1);
        } else {
          setPublicCount(manifesto.publicCount - 1);
        }
      }
    }
  };

  const makeStatement = (statement: IStatement, idx: number) => (
    !statement.deleted ? <li key={idx} className={styles.ManifestoItem}>
      <StatementRow
        statement={statement}
        textClassName={styles.StatementText}
      />
      {loggedInUser && (
        <span>
          {contains(statement) ? (
            <>in your manifesto</>
          ) : (
            <span onClick={() => add(statement)}>
              <AddToManifestoIcon />
            </span>
          )}
        </span>
      )}
    </li>: false
  );

  const makeDraggableStatement = (statement: IStatement, idx: number, publicStm:boolean) => (
    !statement.deleted ? <Draggable draggableId={`${statement.id}`} index={idx} key={statement.id}>
      {(provided) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={styles.ManifestoItem}
        >
          <StatementRow
            statement={statement}
            textClassName={styles.StatementText}
            isPublic={ publicStm }
          />
          <span className={styles.ManifestoDrag}>
            <StatementDragIcon />
          </span>
        </li>
      )}
    </Draggable> : null
  );

  return (<DarkPage>
    <div className={styles.ManifestoPage}>
      <PageHeader className={styles.PageHeader}>
        {isMyManifesto
          ? "My Manifesto"
          : `XR Manifesto` /* of ${manifesto.creator?.name}` */ }
      </PageHeader>
      {/* <ShareButton
        text={`Check this Critical XR manifesto ${
          manifesto.creator?.name ? `of ${manifesto.creator?.name}` : ""
        }`}
        url={
          isMyManifesto
            ? window.location.href.replace(/me$/, manifesto.uuid ?? "me")
            : undefined
        }
        className={styles.ShareButton}
      /> */}
      {!isMyManifesto && <ul>{publicManifesto.map(makeStatement)}</ul>}
      {isMyManifesto && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="public">
            {(provided) => (
              <ul ref={provided.innerRef} {...provided.droppableProps}>
                {publicManifesto.map((stm, idx) => makeDraggableStatement(stm, idx, true))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
          <Droppable droppableId="drop-sep">
            {(provided) => (
              <ul ref={provided.innerRef} {...provided.droppableProps}>
                <Draggable draggableId="drag-sep" index={0}>
                  {(provided) => (
                    <li
                      className={styles.PublicCountSeparator}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <label>My Manifesto</label>
                      <button
                        disabled={manifesto.publicCount <= 0}
                        onClick={() =>
                          setPublicCount(manifesto.publicCount - 1)
                        }
                      ></button>
                      <hr />
                      <label>Bookmarks</label>
                      <button
                        disabled={
                          manifesto.publicCount >= manifesto.statements.length
                        }
                        onClick={() =>
                          setPublicCount(manifesto.publicCount + 1)
                        }
                      ></button>
                      <span>
                        <BarDragIcon />
                      </span>
                    </li>
                  )}
                </Draggable>
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
          <Droppable droppableId="private">
            {(provided) => (
              <ul ref={provided.innerRef} {...provided.droppableProps}>
                {privateManifesto.map((statement, idx) =>
                  makeDraggableStatement(statement, idx, false)
                )}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div></DarkPage>
  );
};

export default ManifestoPage;
