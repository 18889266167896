import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';

interface IProps{ 
  children: JSX.Element[] | JSX.Element;
}

const addDarkBgClass = () => {
  const body = document.getElementsByTagName("body");
  body.length > 0 && body[0].classList.add("darkbg");
}
const removeDarkBgClass = () => {
  const body = document.getElementsByTagName("body");
  body.length > 0 && body[0].classList.remove("darkbg");
}

const DarkPage: React.FC<IProps> = ( {  children } ) => {

  
  const history = useHistory();

  const darkPages = [
    '/',
    '/login',
    '/signup',
    '/password/forgot',
    '/password/reset',
    '/account/me',
    '/introduction',
    '/introduction/',
    '/avatarpicker',
    '/avatarpicker/',
  ];

  if(
    darkPages.includes(window.location.pathname)
  ){
    addDarkBgClass();
  }else{
    removeDarkBgClass();
  }
  
  useEffect(() => {
    if(
      darkPages.includes(window.location.pathname)
    ){
      addDarkBgClass();
    }else{
      removeDarkBgClass();
    }
    const unlisten = history.listen((location, action) => {
      // Apply route change logic, i.e. dispatch to store
      // console.log(location.pathname);
      // console.log('Route changed', { location, action });
      if(
        !darkPages.includes(location.pathname)
      ){
        // console.log("hide bg");
        removeDarkBgClass();
      }
    });
    return unlisten;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, window.location.pathname])

  useEffect(() => {
    return () => {
      // componentwillunmount in functional component.
      // Anything in here is fired on component unmount.
      removeDarkBgClass();
    }
}, [])

  return <>{children}</>
}

export default DarkPage;


