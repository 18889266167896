/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import styles from "./StatementPage.module.css";
// import classnames from "classnames";
import {Link, useHistory, useParams } from "react-router-dom";
import useStatement from "../../hooks/useStatement";
import PageHeader from "../App/PageHeader";
import ContentSection from "../App/ContentSection";
import StatementRow from "../UserInterfaceElements/StatementRow";
import { removeModerationClass } from "../Moderation/moderationFunctions";
import Breadcrumb from "../UserInterfaceElements/Breadcrumb";

import CommentsListing from "./CommentsListing";


interface IRouteParams {
  id: string;
}

const ModerateReactionsPage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<IRouteParams>();
  const [breadcrumb, setBreadcrumb] = useState<Array<JSX.Element>>([<></>]);
  //const { statements } = useStatementsContext();
  //const history = useHistory();

  const statement = useStatement(parseInt(id));

  //const [statements, setStatements] = useState<IStatement[]>([]);

  let textLength = 0;
  let longText = textLength > 240;
  let blur1OffsetY = longText ? 20 : 80;
  
  useEffect(() => {
    window.location.pathname.substring(0, 9) === "/moderate" && setBreadcrumb([
      <Link to="/moderate/" >Moderation</Link>,
      <Link to="/moderate/statements" >Statements</Link>
    ]);

    if (statement !== undefined) {
      textLength = statement.text.length;
      longText = textLength > 240;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      blur1OffsetY = longText ? 20 : 80;
    }

    const unlisten = history.listen((location, action) => {
      console.log('Route changed', { location, action });
      // Apply route change logic, i.e. dispatch to store
      if(location.pathname.substring(0, 9) !== '/moderate'){
        removeModerationClass();
      }
    });
    return unlisten;
  }, [statement]);

  return (
    <div className={styles.ModerateStatementsPage}>
      <Breadcrumb linkStack={breadcrumb} />
      <PageHeader>Moderate Reactions</PageHeader>
      <ContentSection>
        {statement && <StatementRow statement={statement} />}
        { statement && <CommentsListing statement={statement} inputComment={false} />}
      </ContentSection>
    </div>
  );
};

export default ModerateReactionsPage;
