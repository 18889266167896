import { useCallback, useState } from "react";
import { IUser, useUsersContext } from "../context/UsersContext";
import { useAuthUserContext } from "../context/UserContext";

const useModerateUser = () => {
  const { setUsers } = useUsersContext();
  const [user, setUser] = useState<IUser>();
  const { API } = useAuthUserContext();

  const moderate = useCallback(
    (uuid:string, deleted: boolean, moderator: boolean ) => {
      API!.post(`/user/${uuid}/moderate`, { uuid, deleted, moderator}).then((res) => {
        const newUser: IUser = res.data;
        setUsers((users: IUser[]) => {
          //console.log("User", user, users);
          if (users) {
            return [newUser, ...users.filter(user => user.uuid!==newUser.uuid)]
          } else {
            return [newUser];
          }
        });
        setUser(newUser);

      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [API, setUsers, user]
  );

  return { user, moderate };
};

export default useModerateUser;
