import React, {
  CSSProperties,
  forwardRef,
  // useImperativeHandle,
  // useState,
} from "react";
import styles from "./GradientBackground.module.css";
import cx from "classnames";
// import randomInt from "../../randomInt";
// import { motion, useMotionValue } from "framer-motion";

//const COLS = 20; // change this number only
//const ROWS = COLS * 2; // too many because we're never that tall

// const colors = [
//   "rgb(125, 235, 180)",
//   "rgb(90, 105, 235)",
// ];

export interface IGradientBackgroundRef {
  update: () => void;
}
interface IProps {
  className?: string;
  style?: CSSProperties;
}
const GradientBackground = forwardRef<IGradientBackgroundRef, IProps>(
  ({ className, style }, ref) => {
    // const [opacities, setOpacities] = useState(generateOpacities());
    // const [colors] = useState(generateColors());

    // useImperativeHandle(ref, () => ({
    //   update: () => {
    //     setOpacities(() => generateOpacities());
    //   },
    // }));

    return (
      <div className={cx(styles.GradientBackground, className)} style={ {...style, backgroundImage: `linear-gradient(to left, #7BEBB4, #5A69EB)` }}>
        {/* <svg
          viewBox={`0 0 ${COLS * 10} ${ROWS * 10}`}
          preserveAspectRatio="xMidYMid slice"
        >
          {[...new Array(COLS)].map((_, col) => {
            return [...new Array(ROWS)].map((_, row) => {
              const idx = row * COLS + col;
              return (
                <rect
                  key={idx}
                  x={col * 10}
                  y={row * 10}
                  width={10}
                  height={10}
                  style={{
                    fill: colors[0],
                    // opacity: motionopacities[idx],
                  }}
                />
              );
            });
          })}
        </svg>  */}
      </div>
    );
  }
);

export default GradientBackground;
