import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useAuthUserContext } from "../../context/UserContext";
import useQuery from "../../hooks/useQuery";
import PageHeader from "../App/PageHeader";
import ContentSection from "../App/ContentSection";

import styles from "./CreatePage.module.css";
import ButtonGroup from "../UserInterfaceElements/ButtonGroup";
import Button from "../UserInterfaceElements/Button";
import DarkPage from "../UserInterfaceElements/DarkPage";

interface CreatePageProps {}

const CreatePage: React.FC<CreatePageProps> = () => {
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const { create, error } = useAuthUserContext();
  const redirectUrl = useQuery("redirect");

  const doSignup = () => {
    email && password && create(email, password, redirectUrl);
  };

  // useEffect(() => {
  // console.log("useEffect because user");
  // }, [user]);

  return (<DarkPage>
    <div className={styles.CreatePage}>
      <PageHeader>Sign Up</PageHeader>
      <ContentSection>
      {error && <p>{error}</p>}
        <div className={styles.LoginForm}>
          <input
            placeholder="email"
            value={email}
            onChange={(evt) => setEmail(evt.target.value)}
          />
          <input
            placeholder="password"
            value={password}
            type="password"
            className={styles.PasswordInput}
            onChange={(evt) => setPassword(evt.target.value)}
          />
          <ButtonGroup layout={"horizontal"}>
            <Button disabled={!email || !password} onClick={doSignup}>
              Sign Up
            </Button>
            <Button className={styles.LoginLink} to={`/login`} altStyle={true}>
              Login
            </Button>
          </ButtonGroup>
        </div>
      </ContentSection>
    </div></DarkPage>
  );
};

export default CreatePage;
