import React from "react";
import styles from "./ButtonGroup.module.css";

interface IProps{ 
  className?: string | undefined;
  layout?: "vertical" | "horizontal" | undefined;
  children: JSX.Element[];
}

const ButtonGroup: React.FC<IProps> = ( { layout, className, children } ) => {
  const renderClassName = layout === 'horizontal' ?  
    `${styles.ButtonGroupHorizontal} ${styles.ButtonGroup}`: 
    `${styles.ButtonGroupVertical} ${styles.ButtonGroup}`;

  const customCss = layout === 'horizontal' ? {gridTemplateColumns: children.map(el => "1fr").join(" ")} : {};

  return (
    <div
      className={className ? `${className} ${renderClassName}` : renderClassName}
      style={customCss}
    >{
      children
    }</div>
  )
}

export default ButtonGroup;
